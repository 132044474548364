import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 8-8-8 to an 8 rep max`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`12-Deadlifts (185/125)`}</p>
    <p>{`12-Box Jump Overs (24/20)`}</p>
    <p>{`12-Pushups`}</p>
    <p><em parentName="p">{`*`}{`compare to 4/11/17`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You still have time to register for the Open.  Tomorrow is the next
Rest Day so you can make up 18.1 then.  Sign up now at
`}<a parentName="em" {...{
            "href": "http://games.crossfit.com%C2%A0and"
          }}>{`http://games.crossfit.com%C2%A0and`}</a>{` be sure to add yourself to team
CrossFit the Ville.  Each Saturday the Open wod will be the class wod at
9:00 & 10:00 and we will be judging until 12pm.  So come out to
participate in the Open and cheer on your fellow CrossFitters!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      